<template>
  <div class="k-9sptci" v-loading="loading">
    <div style="display: flex; align-items: center;justify-content: flex-end;">
      <el-link
        class="down_load_link"
        :href="`../../import_template/match_s1_session_result.xlsx`"
        target="_blank"
        style="margin-left: 10px;"
        >下载上报结果导入模板</el-link
      >
      <el-link
        class="down_load_link"
        :href="`../../import_template/match_s1_replay_result.xlsx`"
        target="_blank"
        style="margin-left: 10px;"
        >下载复盘导入模板</el-link
      >
    </div>
    <!-- 顶部设置版型，时间，查询 -->
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
      style="position: relative"
    >
      <el-form-item label="是否设置版型">
        <el-select v-model="searchForm.has_pattern">
          <el-option :value="''" :label="'全部'"></el-option>
          <el-option :value="'1'" :label="'已设置'"></el-option>
          <el-option :value="'2'" :label="'未设置'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <div
          style="display: flex; flex-direction: row; align-items: center; height: 33px"
        >
          <el-radio-group v-model="searchForm.time_type">
            <el-radio :label="'6'">全部</el-radio>
            <el-radio :label="'1'">今天</el-radio>
            <el-radio :label="'2'">明天</el-radio>
            <el-radio :label="'3'">未来3天</el-radio>
            <el-radio :label="'4'">未来一周</el-radio>
            <el-radio :label="'5'">自定义</el-radio>
          </el-radio-group>
          <div
            v-if="searchForm.time_type == '5'"
            style="display: flex; flex-direction: row; margin-left: 10px; margin-right: 10px"
          >
            <el-date-picker
              v-model="searchForm.start_time"
              type="date"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            至
            <el-date-picker
              v-model="searchForm.end_time"
              type="date"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
        <el-button type="primary"  @click="release">发布</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="ItemIsShow(item.prop)"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`match:stage_session`"
            @click="handleGroup(scope.row)"
            style="margin-left: 10px"
            >参赛门派</perButton
          >
          <perButton
            :perm="`match:stage_session`"
            @click="changePattern(scope.row)"
            style="margin-left: 10px"
            >设置版型</perButton
          >
          <perButton
            :perm="`match:stage_session`"
            @click="changeResult(scope.row)"
            style="margin-left: 10px"
            >编辑结果</perButton
          >
          <perButton
            v-if="scope.row.status === 1"
            :perm="`match:stage_session`"
            @click="onReListing(scope.row)"
            style="margin-left: 10px"
            >复盘</perButton
          >
          <perButton
            v-if="scope.row.status === 2"
            :perm="`match:stage_session`"
            @click="onEditReListing(scope.row)"
            style="margin-left: 10px"
            >编辑复盘</perButton
          >

          <!-- <perButton
            v-if="scope.row.status === 2"
            :perm="`match:stage_session`"
            @click="onEditReListingPic(scope.row)"
            style="margin-left: 10px"
            >编辑复盘图</perButton
          > -->
          <perButton
            :perm="`match:stage_session`"
            @click="onOpenSessionDetail(scope.row)"
            style="margin-left: 10px"
            >查看详情</perButton
          >
          <!-- <perButton
            class="broad"
            :perm="`match:stage_session`"
            @click="onBroadcast(scope.row)"
            style="margin-left: 10px"
            >视频直播</perButton
          > -->
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="'设置版型'"
      :visible.sync="patternVisible"
      v-if="patternVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="500px"
    >
      <div
        style="width: 100%; height: fit-content; display: flex; justify-content: center"
      >
        <div style="width: fit-content; height: fit-content">
          <el-form ref="patternForm" :model="patternData" label-width="100px">
            <el-form-item
              label="场次"
              prop="session_name"
              :rules="[
                {
                  required: true,
                  message: '请输入场次',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                v-model="patternData.session_name"
                placeholder="请输入场次"
                style="width: 150px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="开始时间"
              prop="start_time"
              :rules="[
                {
                  required: true,
                  message: '请选择开始时间',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-date-picker
                v-model="patternData.start_time"
                type="datetime"
                placeholder="选择开始日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="结束时间"
              prop="start_time"
              :rules="[
                {
                  required: true,
                  message: '请选择结束时间',
                  trigger: ['blur', 'change'],
                },
                { validator: endTimeCheckRule, trigger: ['blur', 'change'] },
              ]"
            >
              <el-date-picker
                v-model="patternData.end_time"
                type="datetime"
                placeholder="选择结束时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="版型"
              prop="pattern"
              :rules="[
                {
                  required: true,
                  message: '请输入版型',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input v-model="patternData.pattern" type="text"> </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="savePattern">确定</el-button>
              <el-button @click="patternVisible = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="'编辑比赛结果'"
      :visible.sync="battleVisible"
      v-if="battleVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="fit-content"
    >
      <el-form ref="battleForm" :model="battleData" label-width="0">
        <el-form-item label="">
          <div
            style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%"
          >
            场次：
            <div style="margin-left: 10px">
              {{ battleData.session_name }}
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label=""
          prop="result.result"
          :rules="[
            {
              required: true,
              message: '请选择比赛结果',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <div
            style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%"
          >
            比赛结果：
            <el-radio-group
              v-model="battleData.result.result"
              style="margin-left: 10px"
            >
              <el-radio :label="1">神民阵营胜利</el-radio>
              <el-radio :label="2">狼人阵营胜利</el-radio>
              <el-radio :label="3">第三方阵营获胜</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item
          label=""
          prop="result.video_url"
          :rules="[
            {
              required: false,
              message: '请输入视频地址',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <div
            style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%"
          >
            视频地址：
            <el-input
              v-model="battleData.result.video_url"
              type="text"
              placeholder="请输入视频地址"
              style="width: 400px; margin-left: 10px"
            >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="">
          <el-upload
            class="upload-demo"
            style="text-align: right"
            action=""
            :on-change="handleChange"
            :multiple="false"
            :show-file-list="false"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            :auto-upload="false"
          >
            <el-button size="small" type="primary">导入上报数据Excel</el-button>
          </el-upload>
          <el-table
            :data="battleData.battleDataItems"
            border
            max-height="350"
            style="margin-top: 5px"
          >
            <el-table-column label="门派ID" width="100">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.sect_id'"
                  label-width="0"
                >
                  {{ scope.row.sect_id }}
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="门派名称" width="100">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.sect_name'"
                  label-width="0"
                >
                  {{ scope.row.sect_name }}
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="阵营" width="150">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.camp'"
                  label-width="0"
                  :rules="[
                    {
                      required: true,
                      message: '请选择阵营',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-select v-model="scope.row.camp">
                    <el-option :value="'1'" label="神民阵营"></el-option>
                    <el-option :value="'2'" label="狼人阵营"></el-option>
                    <el-option :value="'3'" label="第三方阵营"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="座位号" width="120">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.site'"
                  label-width="0"
                  :rules="[
                    {
                      required: true,
                      message: '请输入座位号',
                      trigger: ['blur', 'change'],
                    },
                    {
                      validator: validatorInteger,
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-input
                    type="number"
                    placeholder="座位号"
                    v-model="scope.row.site"
                    maxlength="2"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="身份类型" width="100">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.identity_type'"
                  label-width="0"
                  :rules="[
                    {
                      required: true,
                      message: '请选择身份类型',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-select v-model="scope.row.identity_type">
                    <el-option :value="'1'" label="狼"></el-option>
                    <el-option :value="'2'" label="神"></el-option>
                    <el-option :value="'3'" label="民"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="身份" width="100">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.identity'"
                  label-width="0"
                  :rules="[
                    {
                      required: true,
                      message: '请输入身份',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-input
                    placeholder="身份"
                    v-model="scope.row.identity"
                    maxlength="8"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="门派得分" width="120">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.score'"
                  label-width="0"
                  :rules="[
                    {
                      required: true,
                      message: '请输入门派得分',
                      trigger: ['blur', 'change'],
                    },
                    { validator: validatorScore, trigger: ['blur', 'change'] },
                  ]"
                >
                  <el-input
                    placeholder="门派得分"
                    v-model="scope.row.score"
                    maxlength="8"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="个人UID" width="110">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.player_uid'"
                  label-width="0"
                  :rules="[
                    {
                      required: true,
                      message: '请输入个人UID',
                      trigger: ['blur', 'change'],
                    },
                    {
                      min: 8,
                      max: 8,
                      message: '个人UID为8位字符',
                      trigger: ['blur', 'change'],
                    },
                    { validator: idValidator, trigger: ['blur', 'change'] },
                  ]"
                >
                  <el-input
                    placeholder="个人UID"
                    v-model="scope.row.player_uid"
                    maxlength="8"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="个人得分" width="120">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.player_score'"
                  label-width="0"
                  :rules="[
                    {
                      required: true,
                      message: '请输入个人得分',
                      trigger: ['blur', 'change'],
                    },
                    { validator: validatorScore, trigger: ['blur', 'change'] },
                  ]"
                >
                  <el-input
                    placeholder="个人得分"
                    v-model="scope.row.player_score"
                    maxlength="8"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="评选类型" width="100" align="center">
              <template slot-scope="scope">
                <el-form-item label="" label-width="0">
                  <el-select v-model="scope.row.selection_type">
                    <el-option :value="'1'" label="普通"></el-option>
                    <el-option :value="'2'" label="MVP"></el-option>
                    <el-option :value="'3'" label="SVP"></el-option>
                    <el-option :value="'-1'" label="背锅"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="积分明细" width="400">
              <template slot-scope="scope">
                <el-form-item
                  label=""
                  :prop="'battleDataItems.' + scope.$index + '.score_details'"
                  label-width="0"
                >
                  <el-input
                    placeholder="积分明细"
                    v-model="scope.row.score_details"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveBattleResult">确定</el-button>
          <el-button @click="battleVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <session_detail
      v-model="showSessionDetail"
      @close="detail_session_id = ''"
      :match_id="match_id"
      :session_id="detail_session_id"
    ></session_detail>
    <!-- 复盘弹窗 -->
    <ReListingDialog
      v-model="showReListingDialog"
      :type="reListingType"
      :rowData="reListingRowData"
      @confirm="onReListingConfirm"
    />

    <el-dialog
      title="编辑复盘图"
      :visible.sync="showReListingDialogPic"
      v-if="showReListingDialogPic"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form ref="bannerForm" :model="bannerData" label-width="100px">
        <el-form-item
          label="图片"
          prop="replay_image_path"
          :rules="[
            {
              required: true,
              message: '请上传图片',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeImageUpload"
            action="#"
            :http-request="httpUploadRequest"
          >
            <img
              v-if="bannerData.replay_image_path"
              :src="bannerData.replay_image_path"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBanner">确定</el-button>
          <el-button @click="showReListingDialogPic = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import session_detail from "../../components/session_detail";
import { randomStr } from "@/utils/randomStr";
import XLSX from "xlsx";
import ReListingDialog from "../../components/info_s1/ReListingDialog.vue";
import { isColumnShow } from "@/utils/column";
import { uploadOssUrl } from "@/utils/uploadUrl";
export default {
  name: "s1_session",
  components: {
    perButton,
    session_detail,
    ReListingDialog,
  },
  computed: {
    ...mapState("match", ["match_types"]),
    ItemIsShow() {
      return function(val) {
        //如果类型等于预选赛，则隐藏周
        if (this.matchType == "preliminaries") {
          return isColumnShow(
            val,
            ["group", "season_name"],
            this.matchType,
            "preliminaries"
          );
        } else if (this.matchType == "league") {
          //如果类型等于联赛，则隐藏阶段
          return isColumnShow(val, ["stage"], this.matchType);
        }
      };
    },
  },
  data() {
    return {
      bannerData: {
        _id: "",
        replay_image_path: "",
      },
      type_id: "s1",
      match_id: "",
      loading: false,
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      stage: "",
      searchForm: {
        has_pattern: "",
        time_type: "6",
        start_time: "",
        end_time: "",
      },
      dataTable: [],
      // 表格头部数据，分组场次等
      dataList: [
        {
          prop: "group",
          label: "周期",
          width: 100,
          formatter: (row) => {
            return "第" + row.group + "周";
          },
        },
        {
          prop: "season_name",
          label: "赛段",
          width: 100,
        },
        {
          prop: "stage",
          label: "分组",
          width: 100,
          formatter: (row) => {
            return "第" + row.stage + "周";
          },
        },
        {
          prop: "index",
          label: "场次",
          width: 180,
          formatter: (row) => {
            return row.session_name;
          },
        },
        {
          prop: "start_time",
          label: "开始时间",
          width: 200,
          formatter: this.timeFormat,
        },
        {
          prop: "end_time",
          label: "结束时间",
          width: 200,
          formatter: this.timeFormat,
        },
        {
          prop: "pattern",
          label: "版型",
          width: 200,
          formatter: (row) => {
            return (
              (row.pattern && row.pattern.length > 0 && row.pattern) || "--"
            );
          },
        },
        {
          prop: "pattern_set",
          label: "版型设置",
          width: 100,
          formatter: (row) => {
            return (
              (row.pattern && row.pattern.length > 0 && "已设置") || "未设置"
            );
          },
        },
        {
          prop: "match_status",
          label: "比赛状态",
          width: 100,
          formatter: (row) => {
            let currentTime = new Date(row.current_time);
            if (currentTime < new Date(row.start_time)) {
              return "未开始";
            } else if (currentTime > new Date(row.end_time)) {
              return "已结束";
            } else {
              return "比赛中";
            }
          },
        },
        {
          prop: "status",
          label: "场次状态",
          width: 100,
          formatter: (row) => {
            switch (row.status) {
              case 0: {
                let currentTime = new Date(row.current_time);
                if (currentTime >= new Date(row.end_time)) {
                  return "待上报结果";
                }

                return "--";
              }
              case 1:
                return "待复盘";
              case 2:
                return "已复盘";
            }
          },
        },
      ],
      patternVisible: false,
      patternData: {
        session_name: "",
        _id: "",
        index: "",
        start_time: "",
        end_time: "",
        pattern: "",
      },
      battleVisible: false,
      battleData: {
        session_id: "",
        group: "",
        index: "",
        result: {
          result: 1,
          video_url: "",
          detail: [],
        },
        groups: [],
        battleDataItems: [],
      },
      detail_session_id: "",
      showSessionDetail: false, //是否显示场次详情弹窗
      showReListingDialog: false, //是否显示复盘弹窗
      showReListingDialogPic: false, //是否显示复盘图弹窗
      reListingType: 1, //复盘的类型：1新增复盘；2编辑复盘
      reListingRowData: {}, //复盘的row数据
      matchType: "",
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        this.matchType = localStorage.getItem("matchType");
        this.type_id = val.query.type_id;
      },
      immediate: true,
    },
  },
  methods: {
    idValidator(rule, value, callback) {
      if (/[^0-9,A-Z,a-z]/.test(value.trim())) {
        callback(new Error("只能包含数字和英文字母"));
      } else {
        callback();
      }
    },
    handleGroup(row) {
      this.removeTabByPath(`/match/info/info_s1/session_group`);
      this.removeTabByPath(`/match/info/info_s1/session_group_league`);
      //不等于联赛的时候
      if (this.matchType != "league") {
        this.$router.push({
          path: `/match/info/info_s1/session_group`,
          query: {
            match_id: this.match_id,
            stage: row.stage,
            group: row.group,
            index: row.index,
            type_id: this.type_id,
            session_id: row._id,
            session_name: row.session_name,
          },
        });
      } else {
        this.$router.push({
          path: `/match/info/info_s1/session_group_league`,
          query: {
            match_id: this.match_id,
            stage: row.stage,
            group: row.group,
            index: row.index,
            type_id: this.type_id,
            session_id: row._id,
            session_name: row.session_name,
            season_name: row.season_name,
          },
        });
      }
    },
    changePattern(row) {
      this.patternData.session_name = row.session_name;
      this.patternData.start_time = row.start_time;
      this.patternData.end_time = row.end_time;
      this.patternData._id = row._id;
      this.patternData.pattern = row.pattern;
      this.patternData.index = this.handleGroupSwitch(row);
      this.patternVisible = true;
    },
    handleGroupSwitch(row) {
      if (this.matchType != "league") {
        return `第${row.group}组第${row.index}场`;
      } else {
        return `第${row.index}场`;
      }
    },
    async changeResult(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match.stage_session_result({
        match_id: this.match_id,
        session_id: row._id,
      });
      this.loading = false;
      if (errorCode != "0000") {
        return;
      }
      //设置场次
      this.battleData.session_name = row.session_name;
      this.battleData.session_id = row._id;
      this.battleData.index = this.handleGroupSwitch(row);
      this.battleData.groups = data.groups;
      this.battleData.result = {
        result: 1,
        video_url: "",
        detail: [],
      };

      if (data.result) {
        this.battleData.result = {
          result: data.result.result,
          video_url: data.result.video_url,
          detail: data.result.battle_result,
        };
      }

      this.battleData.battleDataItems = data.groups.map((r) => {
        let detail = this.battleData.result.detail.find(
          (d) => d.sect_id == r.sect_id
        );

        let item = {
          sect_id: r.sect_id,
          sect_name: r.sect_name,
          camp: (detail && detail.camp) || "1",
          site: (detail && detail.site) || "",
          identity: (detail && detail.identity) || "",
          identity_type: (detail && detail.identity_type) || "",
          score: detail ? detail.score + "" : "",
          player_uid: (detail && detail.player_uid) || "",
          player_score: detail ? detail.player_score + "" : "",
          selection_type: (detail && detail.selection_type) || "1",
          score_details: (detail && detail.score_details) || "",
        };

        return item;
      });

      this.battleVisible = true;
    },
    saveBattleResult() {
      this.$refs.battleForm.validate(async (valid) => {
        if (valid) {
          let items = this.battleData.battleDataItems.map((r) => {
            let item = {
              ...r,
            };

            delete item.sect_name;
            return item;
          });

          this.loading = true;
          let saveData = {
            session_id: this.battleData.session_id,
            match_id: this.match_id,
            result: this.battleData.result.result,
            video_url: this.battleData.result.video_url,
            detail: items,
          };
          console.log('1111111111111111111',JSON.stringify(saveData));
          let type = {
            type_id: this.type_id,
            matchType: this.matchType,
          };
          this.loading = false;
          let {
            data,
            errorCode,
          } = await this.$http.match.stage_set_session_result(saveData, type);
          if (errorCode != "0000") {
            return;
          }

          this.$message.success("保存成功");
          this.battleVisible = false;
          let session = this.dataTable.find((r) => r._id == data.session_id);
          if (session) {
            session.status = data.status;
          }
          //临时处理方法 防止复盘导入不进去
          setTimeout(() => {
            // location.reload();
          }, 500);
        }
      });
    },
    endTimeCheckRule(rule, value, callback) {
      if (
        this.patternData.start_time !== "" &&
        this.patternData.end_time != ""
      ) {
        let start = new Date(this.patternData.start_time);
        let end = new Date(this.patternData.end_time);
        if (start > end) {
          callback(new Error("结束时间必须大于开始时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    timeFormat(row, column, cellValue, index) {
      if (cellValue && cellValue.length) {
        return new Date(cellValue).format("yyyy/MM/dd hh:mm:ss");
      } else {
        return cellValue;
      }
    },
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    validatorInteger0(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于或等于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) >= 0) {
        callback();
      } else {
        callback(new Error("请输入大于或等于0的整数"));
      }
    },
    validatorInteger2(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入整数"));
      }

      if (Number.isInteger(Number(value))) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    },
    validatorScore(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }
      if (!/^[-+]?\d+(\.\d{1,2})?$/.test(value)) {
        callback(new Error("请输入整数或2位小数"));
      } else {
        callback();
      }
    },
    savePattern() {
      this.$refs.patternForm.validate(async (valid) => {
        if (valid) {
          // session_name如果没有name则显示index
          let { data, errorCode } = await this.$http.match.stage_set_pattern({
            match_id: this.match_id,
            _id: this.patternData._id,
            pattern: this.patternData.pattern,
            start: this.patternData.start_time,
            end: this.patternData.end_time,
            session_name: this.patternData.session_name,
          });
          if (errorCode != "0000") {
            return;
          }

          this.$message.success("保存成功");

          let current = this.dataTable.find((r) => r._id == data._id);
          if (current) {
            current.pattern = data.pattern;
            current.start_time = data.start_time;
            current.end_time = data.end_time;
          }

          this.patternVisible = false;
          this.handleQuery();
        }
      });
    },
    //添加banner
    saveBanner() {
      this.$refs.bannerForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true;
          let saveData = {
            session_id: this.detail_session_id,
            replay_image_path: this.bannerData.replay_image_path,
            match_id: this.match_id,
          };

          let { data, code } = await this.$http.match.saveReplayPic(saveData);
          this.loading = false;

          if (code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });

            this.showReListingDialogPic = false;
            this.handleQuery();
          }
        }
      });
    },
    beforeImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.bannerData.replay_image_path = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      //当等于全部的时候，走time_type==5自定义的逻辑，去除开始时间 结束时间的限制
      if (this.searchForm.time_type == 6) {
        var tempType = 5;
        this.searchForm.start_time = "";
        this.searchForm.end_time = "";
      } else {
        // eslint-disable-next-line no-redeclare
        var tempType = this.searchForm.time_type;
      }
      const { data, errorCode } = await this.$http.match.stage_session_list({
        ...this.searchForm,
        has_pattern: this.searchForm.has_pattern,
        time_type: tempType,
        start_time: this.searchForm.start_time,
        end_time: this.searchForm.end_time,
        match_id: this.match_id,
        stage: this.stage,
        pageNum,
        pageSize: this.pageSize,
      });
      console.log(111);
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    //上传文件时处理方法
    handleChange(file, fileList) {
      let fileTemp = file.raw;
      if (fileTemp) {
        if (
          fileTemp.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          fileTemp.type == "application/vnd.ms-excel"
        ) {
          this.importfxx(fileTemp);
        } else {
          this.$message({
            type: "warning",
            message: "附件格式错误，请删除后重新上传！",
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！",
        });
      }
    },
    importfxx(e) {
      let _this = this;
      let f = event.currentTarget.files[0];
      let reader = new FileReader();
      FileReader.prototype.readAsBinaryString = function(f) {
        let binary = "";
        let pt = this;
        let wb; //读取完成的数据
        let outdata;
        // let reader = new FileReader();
        reader.onload = function(e) {
          let bytes = new Uint8Array(reader.result);
          let length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          //此处引入，用于解析excel
          let XLSX = require("xlsx");
          wb = XLSX.read(binary, {
            type: "binary",
          });
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
            header: 1,
          });

          //对以前代码进行注释
          let datas = [];
          //拿到导入的excel
          for (let i = 2; i < outdata.length; i++) {
            let item = outdata[i];
            let sect_id = item[0];
            let sect_name = item[1];
            let sect_group = _this.battleData.groups.find(
              (r) => r.sect_id == sect_id
            );

            //暂时不动
            if (sect_id && (sect_id + "").length) {
              let camp = "1";
              switch (item[2]) {
                case "神民阵营":
                  camp = "1";
                  break;
                case "狼人阵营":
                  camp = "2";
                  break;
                case "第三方阵营":
                  camp = "3";
                  break;
                default:
                  break;
              }

              let selection_type = "1";
              switch (((item[9] || "") + "").trim()) {
                case "普通":
                  selection_type = "1";
                  break;
                case "MVP":
                  selection_type = "2";
                  break;
                case "SVP":
                  selection_type = "3";
                  break;
                case "背锅":
                  selection_type = "4";
                  break;
                default:
                  break;
              }

              let identity_type = "1";
              switch (item[4]) {
                case "狼":
                  identity_type = "1";
                  break;
                case "神":
                  identity_type = "2";
                  break;
                case "民":
                  identity_type = "3";
                  break;
                default:
                  break;
              }

              datas.push({
                sect_name: sect_name + "",
                sect_id: sect_id + "",
                camp: camp,
                site: (item[3] || "") + "",
                identity_type: identity_type,
                identity: (item[5] || "") + "",
                score: item[6],
                player_uid: (item[7] || "") + "",
                player_score: item[8],
                score_details: item[10],
                selection_type: selection_type,
              });
            }
          }

          _this.battleData.result.detail = datas;

          //不等于联赛  走之前的逻辑
          if (_this.matchType == "league") {
            _this.battleData.battleDataItems = _this.battleData.groups.map(
              (r) => {
                let detail = _this.battleData.result.detail.find(
                  (d) => d.sect_id == r.sect_id
                );
                let item = {
                  sect_id: r.sect_id,
                  sect_name: r.sect_name,
                  camp: (detail && detail.camp) || "1",
                  site: (detail && detail.site) || "",
                  identity_type: (detail && detail.identity_type) || "",
                  identity: (detail && detail.identity) || "",
                  score: detail ? detail.score + "" : "",
                  player_uid: (detail && detail.player_uid) || "",
                  score_details: (detail && detail.score_details) || "",
                  player_score: detail ? detail.player_score + "" : "",
                  selection_type: (detail && detail.selection_type) || "1",
                };

                return item;
              }
            );
          } else {
            _this.battleData.battleDataItems = _this.battleData.result.detail;
          }

          return outdata;
        };
        reader.readAsArrayBuffer(f);
      };
      reader.readAsBinaryString(f);
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
    /** 复盘 */
    onReListing(rowData) {
      this.reListingType = 1;
      this.reListingRowData = rowData;
      this.showReListingDialog = true;
    },
    // 视频直播
    // onBroadcast(row) {
    //   this.$router.push({
    //     path: `/match/info/info_s1/broadcast`,
    //     query: {
    //       match_id: this.match_id,
    //       session_id: row._id,
    //       season_name: row.season_name,
    //       session_name: row.session_name,
    //       type_id: this.type_id,
    //     },
    //   });
    // },
    /** 编辑复盘 */
    onEditReListing(rowData) {
      this.reListingType = 2;
      this.reListingRowData = rowData;
      this.showReListingDialog = true;
    },
    //编辑复盘图
    onEditReListingPic(rowData) {
      this.reListingType = 2;
      this.detail_session_id = rowData._id;
      this.bannerData.session_id = rowData._id;
      this.bannerData.replay_image_path = rowData.replay_image_path;
      this.showReListingDialogPic = true;
    },

    /** 复盘成功回调 */
    onReListingConfirm() {
      this.loading = true;
      this.handleQuery().finally(() => {
        this.loading = false;
      });
    },
    /** 打开场次详情弹窗 */
    onOpenSessionDetail(row) {
      this.detail_session_id = row._id;
      this.showSessionDetail = true;
    },
      // 发布
   async release() {
    const res =  await this.$http.match.releaseCourse({
      match_id:this.match_id,
      stage: this.stage
    })
        console.log(res);
        }
  },
  async mounted() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.match_id = this.$route.query.match_id;
    this.stage = this.$route.query.stage;
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
.broad {
  margin-top: 10px;
}
</style>
